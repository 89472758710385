@charset "utf-8";

$font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$font-size-base: 1.2rem;

// Import partials from `sass_dir` (defaults to `_sass`)
@import "bootstrap";

nav {
  margin-bottom: 30px;
}

table {
  @extend .table
}

.post-title a {
  color: black;
}

.post-title a:hover {
  color: black;
}

.post-meta {
  font-size: smaller;
  color: #999;
}

.post-summary .date {
  font-size: smaller;
  font-family: courier;
}

.post-summary a {
  color: black;
}

.post-list hr {
  border: 0;
  height: 0px;
  width: 400px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.tag {
  font-size: 10pt;
  color: white;
}

.navbar {
  font-size: 12pt;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

#page-content {
  margin-bottom: 60px;
}

#page-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  font-size: .9em;
}

blockquote {
  margin: 20px 20px 20px 0px;
  padding: 10px 10px 10px 20px;
  border-left: 5px solid #33ff00;
}

figure {
  padding: 10px 10px 5px 10px;
  margin: 20px 10px 20px 10px;
  text-align: center;
}

figcaption {
  margin: 0px 0px 5px 0px;
}

#page-header img {
  height: 20px;
}

.csl-entry {
  margin-top: 10px;
}

#pagination {
  margin-top: 10px;
}

.navbar-toggler-icon {
  font-size: .7em;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.page-link {
  color: black;
}

a.page-link:hover {
  color: black;
}

code {
  color: black;
}

audio {
  width: 100%;
  border: 3px offset; 
}

@media(prefers-color-scheme: dark) {

  body {
    background-color: black;
    color: white;
  }

  .post-title a {
    color: #33ff00;
  }

  .post-title a:hover {
    color: #33ff00;
  }

  .post-meta {
    color: #999;
  }

  .post-summary a {
    color: #33ff00;
  }

  .post-summary {
    color: #33ff00;
  }

  .navbar-toggler {
    background-color: white;
    color: black;
    border: none;
  }

  .navbar-nav > li a {
    color: #33ff00;
  }

  .navbar-nav > li a:hover {
    color: #33ff00;
    text-decoration: underline;
  }

  .pagination > li > a, .pagination > li > span {
    color: #33ff00;
    background-color: black;
  }

  .pagination > .active {
    background-color: #33ff00;
    color: black;
  } 

  .pagination > li > a:hover,
  .pagination > li > a:focus,
  .pagination > li > span:hover,
  .pagination > li > span:focus {
    background-color: #33ff00;
    color: black;
    border-color: white;
  }

  .pagination > .active > a,
  .pagination > .active > a:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    background-color: #33ff00;
    color: black;
    border-color: white;
  }

  a, a:visited {
    color: #33ff00;
  }


  a:hover {
    color: #33ff00;
    text-decoration: underline;
  }

  .navbar-default .navbar-brand {
    color: #33ff00;
  }

  .navbar-default .navbar-brand:hover {
    color: #33ff00;
  }

  img {
  }

  pre {
    background-color: black;
    color: white;
  }

  pre code.hljs {
    background-color: black;
    color: white;
  }

  #page-footer {
    display: none;
  }

  code {
    color: white;
  }
}
